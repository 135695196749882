import constantTestBuilder from "../../../mixins/Support/constantTestBuilder";

export default {
  mixins: [
    constantTestBuilder,
  ],
  computed: {
    element_id: {
      get() {
        return this.$store.state.elementStore.element_id;
      },
      set(value) {
        this.$store.commit("elementStore/setElementId", value);
      },
    },
    type_element: {
      get() {
        return this.$store.state.elementStore.type_element;
      },
      set(value) {
        this.$store.commit("elementStore/setTypeElement", value);
      },
    },
    element_flashcards: {
      get() {
        return this.$store.state.elementStore.element_flashcards;
      },
      set(value) {
        this.$store.commit("elementStore/setElementFlashcards", value);
      },
    },
  },
  methods: {
    getDataDefaultElementFlashcard() {
      return [
        {
          id: null,
          element_id: this.element_id,
          offset: 1,
          word: null,
          phonetic: null,
          word_type: null,
          meaning: null,
          example: "",
          audio_data: null,
          audio_file: null,
          image_data: null,
          image_file: null,
        }
      ];
    },
    checkElementData(type) {
      if (type === "Audio") {
        return this.element_audio_data;
      }
      if (type === "Image") {
        return this.element_image_data;
      }
      if (type === "Post") {
        return this.element_post_data;
      }
      if (type === "Pdf") {
        return this.element_pdf_data;
      }
      if (type === "Embed_yt") {
        return this.element_youtube_data;
      }
      if (type === "Gif") {
        return this.element_gif_data;
      }

      return null;
    },
    getValueElement(type) {
      let valueElement = null;
      if (type === "Audio") valueElement = this.element_type_constant.AUDIO;
      else if (type === "Image") valueElement = this.element_type_constant.IMAGE;
      else if (type === "Video") valueElement = this.element_type_constant.VIDEO;
      // else if( type === 'Embed_yt') valueElement = 4;
      else if (type === "Post") valueElement = this.element_type_constant.POST
      else if (type === "Pdf") valueElement = this.element_type_constant.PDF;
      else if (type === "Album") valueElement = this.element_type_constant.ALBUM;
      else if (type === "Embed_yt") valueElement = this.element_type_constant.YOUTUBE;
      else if (type === "Flashcard") valueElement = this.element_type_constant.FLASHCARD;
      else if (type === "Gif") valueElement = this.element_type_constant.GIF;
      return valueElement;
    },
    checkElementFile(type) {
      if (type === "Image") {
        return this.element_image_file;
      }
      if (type === "Audio") {
        return this.element_audio_file;
      }
      if (type === "Pdf") {
        return this.element_pdf_file;
      }
      if (type === "Gif") {
        return this.element_gif_file;
      }
      return null;
    },
    validateFormElement() {
      let flat = true;
      if (this.type_element == null) {
        this.messError("Hãy chọn loại element !!");
        flat = false;
      }
      if (this.type_element === "Video") {
        if (this.video_element_id == null) {
          this.messError("Bạn chưa chọn tệp tin !!");
          flat = false;
        }
      }
      if (this.type_element === "Image") {
        if (this.element_image_data == null) {
          this.messError("Bạn chưa chọn tệp tin !!");
          flat = false;
        }
      }
      if (this.type_element === "Audio") {
        if (this.element_audio_data == null) {
          this.messError("Bạn chưa chọn tệp tin !!");
          flat = false;
        }
      }
      if (this.type_element === "Pdf") {
        if (this.element_pdf_data == null) {
          this.messError("Bạn chưa chọn tệp tin !!");
          flat = false;
        }
      }
      if (this.type_element === "Post") {
        if (this.element_post_data == null) {
          this.messError("Bạn chưa nhập dữ liệu !!");
          flat = false;
        }
      }
      if (this.type_element === "Album") {
        if (this.album_element_data.length <= 0) {
          this.messError("Bạn chưa chọn tập tin!!");

          flat = false;
        }
      }
      if (this.type_element === "Embed_yt") {
        if (
          this.element_youtube_data == null ||
          this.element_youtube_data === ""
        ) {
          this.$toasted.error("Bạn chưa nhập link youtube !!", {
            theme: "toasted-primary",
            position: "top-right",
            duration: 4000,
          });
          flat = false;
        }
      }
      if (this.type_element === "Flashcard") {
        if (this.element_flashcards.length <= 0) {
          this.messError("Bạn chưa nhập dữ liệu !!");
          flat = false;
        } else {
          for (let i = 0; i < this.element_flashcards.length; i++) {
            let flashcard = this.element_flashcards[i];
            if (this.$utils.isValueInvalid(flashcard.word)) {
              this.messError("Bạn chưa nhập từ ở card " + (i + 1));
              flat = false;
            }
            if (this.$utils.isValueInvalid(flashcard.meaning)) {
              this.messError("Bạn chưa nhập giải nghĩa ở card " + (i + 1));
              flat = false;
            }
          }
        }
      }
      if (this.type_element === "Gif") {
        if (this.element_gif_data == null) {
          this.messError("Bạn chưa chọn tệp tin !!");
          flat = false;
        }
      }
      return flat;
    },
  }
}